<template>
  <div>
    <forgot-password-component
      @ForgotPassword="ForgotPassword"
      :isLoading="isLoading"
      v-if="step == 1"
    />
    <two-step-verification-component
      @TwoStepVerification="TwoStepVerification"
      @back="step = 1"
      :userEmail="userEmail"
      :isLoading="isLoading"
      v-else-if="step == 2"
    />
    <reset-password-component
      @updatePassword="updatePassword"
      :isLoading="isLoading"
      :userEmail="userEmail"
      v-else-if="step == 3"
    />
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from "vee-validate";
import VuexyLogo from "@core/layouts/components/Logo.vue";
import {
  BRow,
  BCol,
  BLink,
  BCardTitle,
  BCardText,
  BImg,
  BForm,
  BFormGroup,
  BFormInput,
  BButton,
} from "bootstrap-vue";
import { required, email } from "@validations";
import imgUrl from "../../../assets/images/svg/forgot-illustration.svg";
import imgUrl1 from "../../../assets/images/svg/rspt1.svg";
import axios from "@/component/axios";
import ForgotPasswordComponent from "./ForgotPasswordComponent.vue";
import TwoStepVerificationComponent from "./TwoStepVerificationComponent.vue";
import ResetPasswordComponent from "./ResetPasswordComponent.vue";
import store from "@/store/index";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  components: {
    VuexyLogo,
    BRow,
    BCol,
    BLink,
    BImg,
    BForm,
    BButton,
    BFormGroup,
    BFormInput,
    BCardTitle,
    BCardText,
    ValidationProvider,
    ValidationObserver,
    ForgotPasswordComponent,
    TwoStepVerificationComponent,
    ResetPasswordComponent,
  },
  data() {
    return {
      userEmail: "react5.jstechno@gmail.com",
      sideImg: require("@/assets/images/pages/forgot-password-v2.svg"),
      // validation
      required,
      email,
      step: 1,
      isLoading: false,
      imgUrl,
      imgUrl1,
      baseApi: process.env.VUE_APP_APIENDPOINT,
    };
  },
  computed: {
    // imgUrl() {
    //   if (store.state.appConfig.layout.skin === 'dark') {
    //     // eslint-disable-next-line vue/no-side-effects-in-computed-properties
    //     this.sideImg = require('@/assets/images/pages/forgot-password-v2-dark.svg')
    //     return this.sideImg
    //   }
    //   return this.sideImg
    // },
  },
  methods: {
    async ForgotPassword(userEmail) {
      this.userEmail = userEmail;
      (this.isLoading = true),
        await axios({
          method: "POST",
          url: `${this.baseApi}auth/admin/reset-password-link`,
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${this.accessToken}`,
          },
          data: {
            email: userEmail,
          },
        }).then((res) => {
          this.isLoading = false;
          if (res.data.success) {
            this.step = 2;
          } else {
            this.$swal({
              title: "Error",
              timer: 5000,
              text: res.data.message,
              icon: "error",
              customClass: {
                confirmButton: "btn btn-danger",
              },
              buttonsStyling: false,
            });
          }
        });
    },
    async TwoStepVerification(otp) {
      (this.isLoading = true),
        await axios({
          method: "POST",
          url: `${this.baseApi}auth/admin/verify-otp`,
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${this.accessToken}`,
          },
          data: {
            email: this.userEmail,
            otp: otp,
          },
        }).then((res) => {
          this.isLoading = false;
          if (res.data.success) {
            this.step = 3;
          } else {
            this.$swal({
              title: "Error",
              timer: 5000,
              text: res.data.message,
              icon: "error",
              customClass: {
                confirmButton: "btn btn-danger",
              },
              buttonsStyling: false,
            });
          }
        });
    },
    async updatePassword(password, cPassword) {
      (this.isLoading = true),
        await axios({
          method: "PUT",
          url: `${this.baseApi}auth/admin/reset-password`,
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${this.accessToken}`,
          },
          data: {
            email: this.userEmail,
            new_password: password,
            confirm_password: cPassword,
          },
        }).then((res) => {
          this.isLoading = false;
          this.$router.push("/login");
          if (res.data.success) {
            this.$swal({
              title: "Success",
              timer: 5000,
              text: res.data.message,
              icon: "success",
              customClass: {
                confirmButton: "btn btn-success",
              },
              buttonsStyling: false,
            });
          } else {
            this.$swal({
              title: "Error",
              timer: 5000,
              text: res.data.message,
              icon: "error",
              customClass: {
                confirmButton: "btn btn-danger",
              },
              buttonsStyling: false,
            });
          }
        });
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-auth.scss";
</style>
