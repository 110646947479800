<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">

      <!-- Brand logo-->
      <!-- <b-link class="brand-logo">
        <vuexy-logo />

        <h2 class="brand-text text-primary ml-1">
          Vuexy
        </h2>
      </b-link> -->
      <!-- /Brand logo-->

      <!-- Left Text-->
      <b-col
        lg="8"
        class="d-none d-lg-flex align-items-center p-5"
      >
        <div class="w-100 d-lg-flex align-items-center justify-content-center px-5">
          <b-img
            fluid
            :src="imgUrl"
            alt="Forgot password V2"
          />
        </div>
      </b-col>
      <!-- /Left Text-->

      <!-- Forgot password-->
      <b-col
        lg="4"
        class="d-flex align-items-center auth-bg px-2 p-lg-5"
      >
        <b-col
          sm="8"
          md="6"
          lg="12"
          class="px-xl-2 mx-auto"
        >

            <div class="w-100 d-lg-flex align-items-center justify-content-center px-5">
              <b-img
              width="170px"
                fluid
                :src="imgUrl1"
                alt="Login V2"
              />
            </div>
          <b-card-title class="mb-1 mt-4">
            Two-Step Verification 💬
          </b-card-title>
          <b-card-text class="mb-2">
            We sent a verification code to your mail. Enter the code from the mail in the field below.
          </b-card-text>
             <b-card-text class="mb-2">
              {{  userEmail }}
            </b-card-text>


          <!-- form -->
          <validation-observer ref="simpleRules">
            <b-form
              class="auth-forgot-password-form mt-2"
                  @submit.prevent="submit"
            >
              <b-form-group
                label="Type your 4 digit security code"
                label-for="security-code"
              >
                <validation-provider
                  #default="{ errors }"
                  name="OTP"
                  rules="required|integer"
                >
                  <b-form-input
                    id="security-code"
                    v-model="otp"
                    :state="errors.length > 0 ? false:null"
                    name="security-code"
                    placeholder=""
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <b-button
                type="submit"
                  style="background-color:black !important"
                  :disabled="isLoading"
                block
              >
              Verify my Mail
              </b-button>
            </b-form>
          </validation-observer>

          <p class="text-center mt-2">
            <div @click="$emit('back')" class="cursor-pointer">
             Didn't get the code?<strong> Resend</strong>
            </div>
          </p>
        </b-col>
      </b-col>
      <!-- /Forgot password-->
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import VuexyLogo from '@core/layouts/components/Logo.vue'
import {
  BRow, BCol, BLink, BCardTitle, BCardText, BImg, BForm, BFormGroup, BFormInput, BButton,
} from 'bootstrap-vue'
import { required, integer } from '@validations'
import imgUrl from '../../../assets/images/svg/two-stap-illustration.svg';
import imgUrl1 from '../../../assets/images/svg/rspt1.svg';
import store from '@/store/index'
import axios from '@/component/axios';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  props:['isLoading','userEmail'],
  components: {
    VuexyLogo,
    BRow,
    BCol,
    BLink,
    BImg,
    BForm,
    BButton,
    BFormGroup,
    BFormInput,
    BCardTitle,
    BCardText,
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      otp: '',
      sideImg: require('@/assets/images/pages/forgot-password-v2.svg'),
      // validation
      required,
      integer,
      imgUrl,
      imgUrl1,
      baseApi: process.env.VUE_APP_APIENDPOINT,
    }
  },
  computed: {
    // imgUrl() {
    //   if (store.state.appConfig.layout.skin === 'dark') {
    //     // eslint-disable-next-line vue/no-side-effects-in-computed-properties
    //     this.sideImg = require('@/assets/images/pages/forgot-password-v2-dark.svg')
    //     return this.sideImg
    //   }
    //   return this.sideImg
    // },
  },
  methods: {
    submit() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          this.$emit('TwoStepVerification', this.otp)
        } else {
          this.$swal({
            title: "Warning",
            timer: 5000,
            text: 'Please Enter OTP First',
            icon: "warning",
            customClass: {
              confirmButton: "btn btn-warning",
            },
            buttonsStyling: false,
          });
        }
      })
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
